<template>
  <div class="submit-container">
    <div class="text-center">
      <img
        alt="success"
        width="66px"
        :src="waitingVerifyImg"
      >
      <h3 class="my-2">
        {{ $t('stepGuideControl.stepWaitingVerify.titleStep') }}
      </h3>
      <div class="d-flex justify-content-center">
        <p v-if="isInfoVerified">
          <img
            alt="success"
            style="width: 40px"
            :src="require('@/assets/images/pages/payment/ic-payment-success-2.svg')"
          >
          {{ $t('stepGuideControl.stepWaitingVerify.textYourInformationHasBeenVerified') }}
        </p>
        <div
          v-else
          class="mb-0 content-success"
        >
          <p>
            {{ $t('stepGuideControl.stepWaitingVerify.textOurTeamWillReview') }}
            <span class="font-weight-bolder">{{ $t('stepGuideControl.stepWaitingVerify.contentWaitingVerify', timeWaiting) }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <div
      v-if="isEcomdyPlatform"
      class="card-list"
    >
      <p>
        {{ $t('stepGuideControl.stepWaitingVerify.whileWaiting') }}
      </p>
      <div
        v-for="(item) in dataCardList"
        :key="item.title"
        class="card-item"
        :data-testid="`card-${item.id}`"
        :class="{ collapsed: item.collapsed }"
      >
        <div
          class="card-item-wrapper"
          @click="item.collapsed = !item.collapsed"
        >
          <div
            class="d-flex justify-content-center align-items-center"
            style="min-width: 60px; height: 60px; borderRadius: 50px"
            :style="{ backgroundColor: item.bgColor }"
          >
            <img
              alt="collapse"
              width="36px"
              :src="require(`@/assets/images/common/${item.icon}`)"
            >
          </div>
          <div
            class="item-collapse"
            :class="item.type === 'collapse' ? 'd-flex' : 'd-block d-md-flex'"
          >
            <div class="gap-2 card-item-container">
              <div class="card-item-title">
                {{ $t(`stepGuideControl.stepWaitingVerify.cardContent.${item.title}`) }}
              </div>
              <div class="card-item-desc">
                {{ item.desc }}
              </div>
            </div>
            <img
              v-if="item.type === 'collapse'"
              alt="collapse"
              width="28px"
              class="card-item-arrow"
              :src="require('@/assets/images/pages/adsAccount/arrow-down.svg')"
            >
            <a
              v-else
              class="card-item-btn"
              :href="item.link"
              target="_blank"
            >
              <btn-loading
                variant-convert="btn-submit"
                style="width: max-content"
              >
                {{ $t('stepGuideControl.stepWaitingVerify.readMore') }}
              </btn-loading>
            </a>
          </div>
        </div>
        <div
          v-if="item.type === 'collapse'"
          v-show="!item.collapsed"
          class="card-collapse"
        >
          <ul>
            <li
              v-for="itemCollapse in item.contentCollapse"
              :key="itemCollapse.text"
            >
              {{ $t(`stepGuideControl.stepWaitingVerify.contentCollapse.${itemCollapse.text}`) }}
              <a
                :href="itemCollapse.link"
                target="_blank"
                class="card-collapse-link"
              >
                {{ $t('stepGuideControl.stepWaitingVerify.readMore') }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div v-if="!isInfoVerified">
      <div v-if="isEmediaPlatform">
        <user-hint
          class="justify-content-center mb-1 mt-5"
          :hide-contact="true"
        />
        <div class="d-flex justify-content-center">
          <img
            class="mr-2 cursor-pointer"
            alt="instagram"
            width="26"
            :src="require('@/assets/images/icons/ic-instagram-square.svg')"
            @click="goToInstagramUrl"
          >
          <img
            class="mr-2 cursor-pointer"
            alt="whats-app"
            width="26"
            :src="require('@/assets/images/icons/ic-whats-app-square.svg')"
            @click="goToWhatsApp"
          >
          <img
            class="mr-2 cursor-pointer"
            alt="email"
            width="26"
            :src="require('@/assets/images/icons/ic-email-square.svg')"
            @click="goToGmail"
          >
        </div>
      </div>
      <user-hint
        v-else
        class="justify-content-center mt-5"
      />
    </div>

    <div
      v-if="hasStepGuide"
      class="d-flex align-items-center justify-content-center mb-2"
      :class="!isInfoVerified ? 'mt-2' : 'mt-5'"
    >
      <btn-loading
        id="btn-back-to-previous"
        data-testid="btn-back-to-previous"
        class="mr-50"
        variant-convert="btn-back"
        type="submit"
        @click.prevent="goBack"
      >
        {{ $t('stepGuideControl.stepWaitingVerify.textBackToPreviousStep') }}
      </btn-loading>
      <btn-loading
        v-if="isInfoVerified"
        id="btn-add-fund"
        data-testid="btn-add-fund"
        class="border-0 button-continue"
        variant-convert="btn-submit"
        pill
        @click.prevent="goNext"
      >
        <span>{{ $t('stepGuideControl.textAddFund') }}</span>
      </btn-loading>
    </div>
  </div>
</template>
<script>
/* eslint-disable global-require */
import { createNamespacedHelpers } from 'vuex'
import UserHint from '@/components/UserHint.vue'
import authMixin from '@/mixins/authMixin'
import envMixin from '@/mixins/envMixin'
import stepGuideMixin from '@/mixins/stepGuideMixin'
import useVerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/useVerticalNavMenu'
import BtnLoading from '@/layouts/components/BtnLoading.vue'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BtnLoading,
    UserHint,
  },

  mixins: [stepGuideMixin, authMixin, envMixin],

  setup() {
    const {
      toggleCollapsed,
    } = useVerticalNavMenu()

    return {
      toggleCollapsed,
    }
  },

  data() {
    const dataCardList = [
      {
        id: 'docs-for-next-steps',
        type: 'collapse',
        title: 'docsForNextSteps',
        desc: this.$t('stepGuideControl.stepWaitingVerify.cardContent.nextStepAfterVerification'),
        icon: 'ic-docs.svg',
        bgColor: 'rgba(29, 185, 195, 0.30)',
        collapsed: false,
        contentCollapse: [
          {
            text: 'modifyInformationGuideline',
            link: 'https://ecomdycom.larksuite.com/docx/EnNidc2UWoemhgxIxBXujOhAs7f',
          },
          {
            text: 'addFundsGuideline',
            link: 'https://docs.ecomdymedia.com/category/step-3-add-fund',
          },
          {
            text: 'createAdAccountGuideline',
            link: 'https://docs.ecomdymedia.com/ecomdy-platform/flow/get-account',
          },
          {
            text: 'importantNoteWithService',
            link: 'https://ecomdycom.larksuite.com/docx/VfnwddpcVomm7ExIPQruOy0xskc',
          },
        ],
      },
      {
        id: 'our-document-center',
        type: 'readMore',
        title: 'ourDocumentCenter',
        desc: 'Learn how to use Ecomdy Media to advertise ...',
        icon: 'ic-docs-center.svg',
        bgColor: 'rgba(242, 37, 72, 0.30)',
        link: 'http://docs.ecomdymedia.com/',
      },
      {
        id: 'tikTok-business-help-center',
        type: 'readMore',
        title: 'tikTokBusinessHelpCenter',
        desc: 'Advertising on TikTok Ads Manager',
        icon: 'ic-docs-tiktok.svg',
        bgColor: 'rgba(122, 179, 76, 0.30)',
        link: 'https://ads.tiktok.com/help/',
      },
    ]

    return {
      dataCardList,
      showSuccessPage: true,
    }
  },

  computed: {
    ...mapGetters(['user']),
    timeWaiting() {
      switch (this.platformName) {
        case 'SBH Media':
          return { timeWaiting: this.$t('stepGuideControl.stepWaitingVerify.24h') }
        default:
          return { timeWaiting: this.$t('stepGuideControl.stepWaitingVerify.date') }
      }
    },

    waitingVerifyImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/pages/adsAccount/icon-waiting-verify-emedia.svg')
      }
      return require('@/assets/images/pages/adsAccount/icon-heart-success.png')
    },
  },

  watch: {
    user: {
      handler(userInfo) {
        if (userInfo?.data?.onboardingStep === 6) {
          this.$router.push('/')
          this.toggleCollapsed()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    goBack() {
      this.$router.push('/update-information')
    },

    goNext() {
      if (this.isOnlyEcomdyPlatform) {
        this.$router.push('/payment/add-fund?tab=paypal')
      } else {
        this.$router.push('/payment/add-fund')
      }
    },

    openLiveChat() {
      if (window.fcWidget) {
        // window.fcWidget.user.setProperties({
        //   isVerify: 'Done',
        // })
        window.fcWidget.open()
      }
    },

    goToInstagramUrl() {
      window.open('https://www.instagram.com/direct/t/17844686495658865')
    },

    goToGmail() {
      window.open('https://mail.google.com/mail/u/0/#inbox?compose=CllgCHrjmRltXScgRgKlGZsJrQQBlQrCTZGtQjxZnMVjHwPrkBwFwmVLDnNsvxFbvJLMCFRvnBV')
    },
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/variables/_variables.scss";

.submit-container {
  width: 667px;
  max-width: 100%;
  margin-inline: auto;
}

.content-success {
  width: 80%;

  & > a {
    color: #6E44FF;
  }

  @media(max-width: 767px) {
    width: 85%;
  }
}

.card-list {
  margin: 24px auto;
  width: 90%;

  .card-item {
    border-radius: var(--border-radius-base);
    padding: 12px;

    background-color: #fff;
  }

  .card-item + .card-item {
    margin-top: 32px;
  }

  .card-item:has(.card-collapse):not(.collapsed) .card-item-wrapper {
    border-radius: 12px;
    padding: 6px;

    background-color: #0000000D;
  }

  .card-item:has(.card-collapse) .card-item-wrapper {
    cursor: pointer;
  }

  .card-item-wrapper {
    transition: all 150ms ease-out;
    display: flex;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
    }
  }

  .card-item-wrapper > :nth-child(2) {
    margin-left: 10px;
  }

  .item-collapse {
    align-items: center;
    justify-content: space-between;
    width: -webkit-fill-available;
  }

  .card-item-container {

    .card-item-title {
      font-size: 20px;
    }

    .card-item-desc {
      font-size: 14px;
      margin-top: 4px;
    }
  }

  .card-item-btn {
    display: flex;
    align-items: center;
    height: 40px;
    font-weight: 400;
    color: #fff;
    cursor: pointer;
    margin-top: 8px;

    @media(min-width: 767px) {
      justify-content: center;
      margin-top: 0;
    }
  }

  .card-item-arrow {
    transition: transform 250ms ease;
  }

  .card-item:not(.collapsed) .card-item-arrow {
    transform: rotate(180deg);
  }

  .card-collapse {
    margin-top: 24px;
    padding-bottom: 18px;
  }

  .card-collapse > ul {
    font-size: 14px;
    margin-bottom: 0;
    list-style-type: decimal;
    padding-left: 18px;

    color: #000000B2;
  }

  .card-collapse > ul > li + li {
    margin-top: 12px;
  }

  .card-collapse-link {
    text-decoration: underline;

    color: #2667FF;
  }
}
</style>
